html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  margin: 0 auto;
  padding: 30px;
}

.links {
  padding-top: 10px;
}

pre {
  padding: 0;
  margin: 0;
}

a {
  color: #4273dc;
  text-decoration: none;
}
